import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import loadable from '@loadable/component';

import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

import './globals.scss';

const Layout = loadable(() => import('./layout/Layout'));
const MainPage = loadable(() => import('./pages/MainPage'));
const NoPage = loadable(() => import('./pages/404'));
const ThankYou = loadable(() => import('./pages/ThankYou'));

const ImageLPv02 = loadable(() => import('./pages/LPs/ImageLPv02'));
const ImageLPnew = loadable(() => import('./pages/LPs/ImageLPnew'));
const ImageLPnew2023 = loadable(() => import('./pages/LPs/ImageLPnew2023'));
const LeadFormLPv02 = loadable(() => import('./pages/LPs/LeadFormLPv02'));

const ImageLP = loadable(() => import('./pages/LeadGen/ImageLP'));
const ContentPage = loadable(() => import('./pages/Content'));

// google analytics and tag manager
const TRACKING_ID = "UA-2171185-13";
ReactGA.initialize(TRACKING_ID);
let gtmIDnum;

if(window.location.href.indexOf("joinbeam") > -1) {
    gtmIDnum = 'GTM-K7SGNK4'
} else { gtmIDnum = 'GTM-WJH797S' }

const tagManagerArgs = {
    gtmId: gtmIDnum
}
TagManager.initialize(tagManagerArgs);

// App
const App = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    const version = 'obie-theme';
    const route = (
            <Route path="/" element={<Layout/>}>
                <Route index element={<MainPage/>} />
                <Route path="*" element={<NoPage />} />
                <Route path="/thank-you" element={<ThankYou />} />
                {/* Lead Gen LPs */}
                <Route path="/general-lp" element={<ImageLPv02 />} />
                <Route path="/general-lp-v2" element={<ImageLPnew />} />
                <Route path="/interactive-floor-projector" element={<ImageLPnew2023 hero='obie-floor' />} />
                <Route path="/interactive-wall-projector" element={<ImageLPnew2023 hero='obie-wall' />} />
                <Route path="/lead-form-lp-v02" element={<LeadFormLPv02 />} />
                <Route path="/lead-gen-medical" element={<ImageLP type='medical' header='successful-businesses' quotesType='testimonials-medical' />} />
                <Route path="/lead-gen-education" element={<ImageLP type='education' header='over-15k-locations' quotesType='testimonials-education' />} />
                <Route path="/lead-gen-fec" element={<ImageLP type='fec' header='loved-by' quotesType='testimonials-fec' />} />
                {/* Content LPs */}
                <Route path='/do-you-have-these-5-essentials-in-your-daycare-classrooms' element={<ContentPage data='daycare-classrooms' version='Obie' />} />
                <Route path='/how-virtual-gaming-is-transforming-health-centers' element={<ContentPage data='education' />} />
                <Route path='/obie-exergaming-improves-aerobic-capacity' element={<ContentPage data='ucla' />} />
            </Route>
        );

    return (
        <div className={version} style={{ display: 'block', position: 'relative', width: '100%', height: '100%', maxWidth: '100%' }}>
            <BrowserRouter>
                <Routes>
                    {route}
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;